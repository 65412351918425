/*global window, document, jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    if (window.SlickSliderOptions !== undefined) {
        jQuery.each(window.SlickSliderOptions, function (key, configuration) {
            var $slider = $('.slick_slider_' + key);

            // Slick 1.3.15 doesn't support .on events, fake the before+afterChange event this way
            // so we can use the event in multiple files (instead of only a single handler)
            $slider.slickSetOption('onBeforeChange', function (slickElement, index) {
                $slider.trigger('beforeChange', [slickElement, index]);
            });

            $slider.slickSetOption('onAfterChange', function (slickElement, index) {
                $slider.trigger('afterChange', [slickElement, index]);
            });
        });
    }
});
