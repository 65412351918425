/*global document, jQuery, window */
jQuery(document).ready(function ($) {
    'use strict';

    function initUspSlider() {
        if (Foundation.MediaQuery.atLeast('large') === false) {
            $('.UspElement-sliderMain.slick-slider').add('.UspElement-sliderNav.slick-slider').unslick();

            $('.UspElement-sliderMain').slick({
                centerMode: true,
                centerPadding: '0px',
                focusOnSelect: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
                draggable: false,
                variableWidth: true,
                asNavFor: '.UspElement-sliderNav'
            });

            $('.UspElement-sliderNav').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.UspElement-sliderMain',
                dots: true,
                arrows: false
            });
        } else if (Foundation.MediaQuery.atLeast('large') === true) {
            $('.UspElement-sliderMain.slick-slider').add('.UspElement-sliderNav.slick-slider').unslick();
        }
    }

    $(window).on('resize', Foundation.util.throttle(function () {
        initUspSlider();
    }, 300));

    initUspSlider();
});
