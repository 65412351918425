/*global window, document, jQuery*/

(function ($) {
    'use strict';

    var iconPath = window.svgPath || '/typo3conf/ext/site_tkn_fe/Resources/Public/Icons/sprite.svg',
        editIcon = '<svg class="Icon Icon--pencil"><use xlink:href="' + iconPath + '#Icon--pencil' + '"></use></svg>',
        widgetTemplate = '<div class="small-12 columns addressCompletionWidget" data-acw>' +
                            '<div class="row collapse align-middle">' +
                                '<div class="small-10 columns">' +
                                    '<div class="addressCompletionWidget-address addressCompletionWidget-address--empty">' +
                                        '<span class="addressCompletionWidget-address-component addressCompletionWidget-address-component--street" data-acw-street></span>' +
                                        '<span class="addressCompletionWidget-address-component addressCompletionWidget-address-component--city" data-acw-city></span>' +
                                    '</div>' +
                                '</div>' +
                                '<div class="small-2 columns text-center">' +
                                    '<a href="#" class="addressCompletionWidget-button" data-acw-exit>' + editIcon + '</a>' +
                                '</div>' +
                            '</div>' +
                        '</div>';

    $(document).on('MaxServ_DutchAddressCompletion_Initialize', function () {
        // Note that the keyup event is removed from the $dacItems on the lines below
        // So if you need to add extra items to this selector, notice that it won't contain a keyup handler
        var $dacItems = $('[data-dac-role="street"], [data-dac-role="city"]');
        var length = $dacItems.length;
        // Remove the keyup event from the dac items, so it's possible to modify your street/city without starting a new
        // xhr request (which could override your input)
        window.setTimeout(function () {
            $dacItems.off('keyup');
        });

        $dacItems.each(function (i) {

            var $field = $(this);
            var $column = $field.closest('.columns');
            var $fieldGroup = $field.closest('fieldset');

            //  Hide this field
            $column.addClass('hide');

            //  Add data-attribute to column
            if (!$column.attr('data-acw-init')) {
                $column.attr('data-acw-init', true);

                //  Add template after the last row
                if (++i === length) {
                    $column.after(widgetTemplate);
                }

                // if a field is changed transfer the value to the label
                $field.on('change', function () {

                    //  Find the elements to combine the street/city in
                    var $cityLabel = $fieldGroup.find('[data-acw-city]');
                    var $streetLabel = $fieldGroup.find('[data-acw-street]');

                    if ($(this).is('[data-dac-role="city"]')) {
                        $cityLabel.text(', ' + $(this).val());
                    } else if ($(this).is('[data-dac-role="street"]')) {
                        $streetLabel.text($(this).val());
                    }

                });
            }

            //  if a user exits the widget show the regular fields and remove the widget component
            $fieldGroup.on('click', '[data-acw-exit]', function (ev) {
                ev.preventDefault();

                $column.removeClass('hide');
                $fieldGroup.find('.addressCompletionWidget').remove();
            });

            //  if automatic resolving fails exit the widget
            $(document).on('MaxServ_DutchAddressCompletion_FailedRequest', function () {
                $fieldGroup.find('[data-acw-exit]').trigger('click');
            });

        });
    });
}(jQuery));
