/*global window,document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var banner = $('.js-slideInSeducer'),
        bannerSideToggle = $('.js-slideInSeducer-titleSide');

    function showBanner() {
        banner.addClass('SlideInSeducer--visible');
    }

    function createCookie(cookieName, cookieVal, cookieMinutes) {
        var expDate = new Date();

        if (document.cookie.indexOf('SlideInSeducer') < 0) {
            // If the cookie has not been set, show the banner
            showBanner();

            expDate.setTime(expDate.getTime() + (cookieMinutes * 60 * 1000));
            document.cookie = cookieName + "=" + escape(cookieVal) + ((cookieMinutes == null) ? "" : ";expires=" + expDate.toGMTString());
        }
    }

    bannerSideToggle.on('click',function () {
        $(this).parents('.js-slideInSeducer').toggleClass('SlideInSeducer--visible');
    });

    window.setTimeout(function () {
        // Set a cookie for 30 minutes
        createCookie('SlideInSeducer', 0, 30);
    }, 500);
});
