/*global document, ContentSlider*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    var items = document.querySelectorAll('.FrankSlider'),
        i;

    for (i = 0; i < items.length; i += 1) {
        items[i].FrankSlider = new FrankSlider(items[i]);
    }
});
