/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    var toggleLink = $('.js-toggleTable');

    toggleLink.on('click',function (e) {
        e.preventDefault();

        $(this).parents('.elementButtons').siblings('.js-productRules').find('.is-abstract').removeClass('is-abstract');
        $(this).remove();
    });
});
