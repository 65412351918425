/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.js-justToggle').on('click',function () {
        var currentElement = $(this),
            justToggle = currentElement.attr('data-just-toggle');

        if (justToggle.length) {
            currentElement.toggleClass(justToggle);
        }
    });
});
