/*global window,document*/
(function () {
    'use strict';

    var ContentSlider,
        requestAnimationFrame = window.MaxServ.requestAnimationFrame,
        dragStart;

    dragStart = function (event) {
        event.preventDefault();
    };

    /**
     * @constructor
     * @param {Element} el - Slider element
     */
    ContentSlider = function (el) {
        /**
         * The slider DOM-node
         * @type {Element}
         */
        this.element = el;

        /**
         * The {@link this.element|slider's} innerWrap, which limits the width of the slider's content
         * @type {Element}
         */
        this.innerWrap = this.element.querySelector('.ContentSlider-innerWrap');

        /**
         * The {@link this.element|slider's} itemWrap that contains the slides
         * @type {Element}
         */
        this.itemWrap = this.element.querySelector('.ContentSlider-itemWrap');

        /**
         * The slides inside the {@link this.element|slider}
         * @type {NodeList}
         */
        this.items = this.element.querySelectorAll('.ContentSlider-item');

        /**
         * The prev button element
         * @type {Element}
         */
        this.prevButton = null;

        if(this.element.dataset.prevbutton) {
            this.prevButton = document.querySelector(this.element.dataset.prevbutton);
        } else {
            this.prevButton = this.element.querySelector('.ContentSlider-button--prev');
        }

        /**
         * The next button element
         * @type {Element}
         */
        this.nextButton = null;

        if(this.element.dataset.nextbutton) {
            this.nextButton = document.querySelector(this.element.dataset.nextbutton);
        } else {
            this.nextButton = this.element.querySelector('.ContentSlider-button--next');
        }

        /**
         * Element that contains the navigation bullets
         * @type {Element}
         */
        this.bulletWrap = this.element.querySelector('.ContentSlider-bulletWrap');

        // Bind 'this' to all methods, so we don't have to use a 'that' reference
        this.init = this.init.bind(this);
        this.addItemListeners = this.addItemListeners.bind(this);
        this.removeItemListeners = this.removeItemListeners.bind(this);
        this.reloadSlides = this.reloadSlides.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onItemFocus = this.onItemFocus.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.updateOffset = this.updateOffset.bind(this);
        this.transitionFinished = this.transitionFinished.bind(this);
        this.slideBack = this.slideBack.bind(this);
        this.slideForward = this.slideForward.bind(this);
        this.bulletClick = this.bulletClick.bind(this);
        this.setActiveBullet = this.setActiveBullet.bind(this);
        this.update = this.update.bind(this);
        this.limitOffset = this.limitOffset.bind(this);

        /**
         * Contains a timestamp when the touchstart was triggered
         * @type {number}
         */
        this.momentTouchStarted = -1;

        /**
         * When touchend is triggered and the user didn't move (that much), allow clicks
         * @type {boolean}
         */
        this.allowClicks = true;

        /**
         * Indicates whether the user is touching the slider at the moment
         * @type {boolean}
         */
        this.isTouching = false;

        /**
         * The number of slides
         * @type {number}
         */
        this.itemCount = this.items.length;

        /**
         * The position where the user started touching the slider
         * @type {{x: number, y: number}}
         */
        this.start = {x: 0, y: 0};

        /**
         * The distance the user dragged since touchstart
         * @type {{x: number, y: number}}
         */
        this.delta = {x: 0, y: 0};

        /**
         * The position of the slider before the user started dragging
         * @type {number}
         */
        this.offset = 0;

        /**
         * Only allows horizontal scrolling when set to true
         * @type {boolean}
         */
        this.horizontalScrollLock = false;

        /**
         * Only allows vertical scrolling when set to true
         * @type {boolean}
         */
        this.verticalScrollLock = false;

        /**
         * @description The width of a slide
         * @type {number}
         */
        this.itemWidth = 0;

        /**
         * Number of visible slides
         * @type {number}
         */
        this.visibleCount = 0;

        /**
         * The width of the {@link this.itemWrap}
         * @type {number}
         */
        this.sliderWrapWidth = 0;

        /**
         * The offset of where the last visible item(s) start
         * @type {number}
         */
        this.lastItemStart = 0;

        // Initialize the slider
        this.init();

        // Call onResize using RAF, to prevent synchronized layouts
        requestAnimationFrame(this.onResize);

        return this;
    };

    /**
     * Initialize the slider, adds event listeners.
     */
    ContentSlider.prototype.init = function () {
        var that = this;

        window.addEventListener('resize', this.onResize);
        window.addEventListener('load', function () {
            if (document.documentElement.className.indexOf('no-flexwrap') > -1) {
                that.itemWrap.style.width = that.sliderWrapWidth + 'px';
            }
        });

        this.element.addEventListener('scroll', function () {
            // When pressing the tab key, you'll automatically scroll to the focused element inside the container. Since
            // we can't control that animation, keep scrollLeft 0 and center the focused element manually
            that.element.scrollLeft = 0;
        });

        if (this.prevButton) {
            this.prevButton.addEventListener('click', this.slideBack);
        }

        if (this.nextButton) {
            this.nextButton.addEventListener('click', this.slideForward);
        }

        this.itemWrap.addEventListener('mouseleave', this.onTouchEnd);
        this.addItemListeners();
    };

    ContentSlider.prototype.addItemListeners = function () {
        var i;

        for (i = 0; i < this.items.length; i += 1) {
            this.items[i].addEventListener('focus', this.onItemFocus);
            this.items[i].addEventListener('click', this.onClick);

            this.items[i].addEventListener('touchstart', this.onTouchStart);
            this.items[i].addEventListener('mousedown', this.onTouchStart);

            this.items[i].addEventListener('touchmove', this.onTouchMove);
            this.items[i].addEventListener('mousemove', this.onTouchMove);

            this.items[i].addEventListener('touchend', this.onTouchEnd);
            this.items[i].addEventListener('touchcancel', this.onTouchEnd);
            this.items[i].addEventListener('mouseup', this.onTouchEnd);

            this.items[i].addEventListener('dragstart', dragStart);
        }
    };

    ContentSlider.prototype.removeItemListeners = function () {
        var i;

        for (i = 0; i < this.items.length; i += 1) {
            this.items[i].removeEventListener('focus', this.onItemFocus);
            this.items[i].removeEventListener('click', this.onClick);

            this.items[i].removeEventListener('touchstart', this.onTouchStart);
            this.items[i].removeEventListener('mousedown', this.onTouchStart);

            this.items[i].removeEventListener('touchmove', this.onTouchMove);
            this.items[i].removeEventListener('mousemove', this.onTouchMove);

            this.items[i].removeEventListener('touchend', this.onTouchEnd);
            this.items[i].removeEventListener('touchcancel', this.onTouchEnd);
            this.items[i].removeEventListener('mouseup', this.onTouchEnd);

            this.items[i].removeEventListener('dragstart', dragStart);
        }
    };

    ContentSlider.prototype.reloadSlides = function () {
        this.items = this.element.querySelectorAll('.ContentSlider-item');
        this.itemCount = this.items.length;

        this.removeItemListeners();
        this.addItemListeners();
        this.onResize();
    };

    /**
     * Handles click events on slides. When clicks are not allowed by this.allowClicks, prevent them
     * @param event
     */
    ContentSlider.prototype.onClick = function (event) {
        if (this.allowClicks === false) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
        }
    };

    /**
     * When resizing, recalculate the itemWidth, visibleCount, sliderWrapWidth and lastItemstart.
     */
    ContentSlider.prototype.onResize = function () {
        var oldItemWidth = this.itemWidth,
            oldSliderWrapWidth = this.sliderWrapWidth,
            itemPadding = 0,
            currentNumberOfBullets,
            requiredNumberOfBullets,
            newBullet;

        this.itemWidth = 0;
        if (this.items.length > 0) {
            itemPadding = parseFloat(window.getComputedStyle(this.items[0]).paddingLeft, 10) * 2;
            this.itemWidth = this.items[0].getBoundingClientRect().width;
        }

        this.visibleCount = Math.floor((this.innerWrap.getBoundingClientRect().width + itemPadding) / parseInt(this.itemWidth, 10));
        this.sliderWrapWidth = this.itemCount * this.itemWidth;
        this.lastItemStart = this.sliderWrapWidth - this.itemWidth * this.visibleCount;

        if (this.itemCount === this.visibleCount) {
            if (this.prevButton !== null) {
                this.prevButton.className += ' invisible';
            }
            if (this.nextButton !== null) {
                this.nextButton.className += ' invisible';
            }

            if (this.bulletWrap !== null) {
                this.bulletWrap.className += ' invisible';
            }
        } else {
            if (this.prevButton !== null) {
                this.prevButton.className = this.prevButton.className.replace(/\s?invisible/g, '');
            }
            if (this.nextButton !== null) {
                this.nextButton.className = this.nextButton.className.replace(/\s?invisible/g, '');
            }

            if (this.bulletWrap !== null) {
                this.bulletWrap.className = this.bulletWrap.className.replace(/\s?invisible/g, '');
            }
        }

        if (this.bulletWrap !== null) {
            currentNumberOfBullets = this.bulletWrap.querySelectorAll('.ContentSlider-bullet').length;
            requiredNumberOfBullets = Math.ceil(this.itemCount / this.visibleCount);

            if (currentNumberOfBullets > requiredNumberOfBullets) {
                while (currentNumberOfBullets > requiredNumberOfBullets) {
                    this.bulletWrap.lastChild.removeEventListener('click', this.bulletClick);
                    this.bulletWrap.removeChild(this.bulletWrap.lastChild);
                    currentNumberOfBullets -= 1;
                }
            } else if (currentNumberOfBullets < requiredNumberOfBullets && requiredNumberOfBullets > 1) {
                while (currentNumberOfBullets < requiredNumberOfBullets) {
                    newBullet = document.createElement('button');
                    newBullet.className = 'ContentSlider-bullet';
                    newBullet.addEventListener('click', this.bulletClick);
                    this.bulletWrap.appendChild(newBullet);

                    currentNumberOfBullets += 1;
                }
            }

            var bullets = this.bulletWrap.querySelectorAll('.ContentSlider-bullet'),
                i;

            for (i = 0; i < bullets.length; i += 1) {
                bullets[i].setAttribute('title', this.bulletWrap.getAttribute('data-labelscrolltopart') + ' ' + (i + 1) + ' ' + this.bulletWrap.getAttribute('data-labelof') + ' ' + requiredNumberOfBullets);
            }

            this.setActiveBullet(0);
        }

        // Size of the slider changed, reset the slider by setting the offset to 0
        if (oldItemWidth !== this.itemWidth || oldSliderWrapWidth !== this.sliderWrapWidth) {
            this.offset = 0;
            requestAnimationFrame(this.updateOffset.bind(this, 0));
        }
    };

    /**
     * When focusing an item, make sure it's visible
     * @param event
     */
    ContentSlider.prototype.onItemFocus = function (event) {
        if (this.isTouching === false) {
            // Get the item's index
            var index = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
            // Calculate the offset for the focused item
            this.offset = this.limitOffset(index * this.itemWidth * -1);
            // Scroll to the focused item
            requestAnimationFrame(this.updateOffset.bind(this, this.offset, {
                transition: true,
                timingFunction: 'ease-in-out'
            }));
        }
    };

    /**
     * Handles touch start events
     * @param event
     */
    ContentSlider.prototype.onTouchStart = function (event) {
        if (this.isTouching === false) {
            this.isTouching = true;
            this.horizontalScrollLock = false;
            this.verticalScrollLock = false;
            // Set the right values in this.start, this var contains the values where the touchevent started
            this.start = {
                x: (event.touches ? event.touches[0].pageX : event.pageX),
                y: (event.touches ? event.touches[0].pageY : event.pageY)
            };
            // We didn't move yet, so set the delta to 0
            this.delta = {
                x: 0,
                y: 0
            };
            this.momentTouchStarted = new Date().getTime();

            // Start the update loop
            requestAnimationFrame(this.update);
        }
    };

    /**
     * Handles touch move events
     * @param event
     */
    ContentSlider.prototype.onTouchMove = function (event) {
        if (this.isTouching === true) {
            // If we're moving the slider by mouse, prevent events + bubbling so you won't accidently trigger a click event
            if (!event.touches) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
            }

            this.delta = {
                x: (event.touches ? event.touches[0].pageX : event.pageX) - this.start.x,
                y: (event.touches ? event.touches[0].pageY : event.pageY) - this.start.y
            };

            // When we're scrolling more Y than X, lock the scroll to vertical
            if ((this.horizontalScrollLock === false && Math.abs(this.delta.y) > Math.abs(this.delta.x)) || this.verticalScrollLock === true) {
                this.verticalScrollLock = true;
                return;
            }

            // Or lock scrolling to horizontal
            if (Math.abs(this.delta.x) > 5 || this.horizontalScrollLock === true) {
                event.preventDefault();
                this.horizontalScrollLock = true;
            }
        }
    };

    /**
     * Gets eecuted when touching one of the slides has ended. Cleanup a couple of variables and slide to the closest slide
     */
    ContentSlider.prototype.onTouchEnd = function () {
        if (this.isTouching === true) {
            var hadVerticalScrollLock = this.verticalScrollLock,
                fastAnimation;

            this.isTouching = false;
            this.horizontalScrollLock = false;
            this.verticalScrollLock = false;

            if (hadVerticalScrollLock === true) {
                return;
            }

            this.allowClicks = Math.abs(this.delta.x) < 20;

            if (this.delta.x === 0) {
                return;
            }

            if (Math.abs(this.delta.x) < 30) {
                // If we've dragged less than 30px, slide back to the original state
                requestAnimationFrame(this.updateOffset.bind(this, this.offset, {
                    transition: true,
                    timingFunction: 'ease-in-out'
                }));
            } else {
                // Use a fast animation when the time between touchstart and touchand is lower than 200ms
                fastAnimation = new Date().getTime() - this.momentTouchStarted < 200;

                if ((this.offset + this.delta.x) > 0) {
                    // We can't go any further to the left than the start of the first slide, so move to 0
                    this.offset = 0;
                    requestAnimationFrame(this.updateOffset.bind(this, 0, {transition: true}));
                } else if (Math.abs(this.offset + this.delta.x) > this.lastItemStart) {
                    this.offset = 0;
                    if (this.itemCount > this.visibleCount) {
                        // We also can't go any further than the last slide
                        this.offset = this.lastItemStart * -1;
                    }
                    requestAnimationFrame(this.updateOffset.bind(this, this.offset, {transition: true}));
                } else if (this.delta.x < 0) {
                    // We were scrolling to the right. Slide to the next item
                    this.offset = Math.floor((this.offset + this.delta.x) / this.itemWidth) * this.itemWidth;
                    requestAnimationFrame(this.updateOffset.bind(this, this.offset, {
                        transition: true,
                        fastAnimation: fastAnimation
                    }));
                } else {
                    // We were scrolling to the left. Slide to the next item
                    this.offset = Math.ceil((this.offset + this.delta.x) / this.itemWidth) * this.itemWidth;
                    requestAnimationFrame(this.updateOffset.bind(this, this.offset, {
                        transition: true,
                        fastAnimation: fastAnimation
                    }));
                }
            }

            this.setActiveBullet(Math.ceil(this.offset / this.itemWidth / this.visibleCount * -1));
        }
    };

    /**
     * Run the continuous updater.
     */
    ContentSlider.prototype.update = function () {
        if (this.isTouching === true && this.verticalScrollLock === false) {
            // Call this function again, so we create a loop. Throttle it with requestAnimationFrame
            requestAnimationFrame(this.update);
            // While running the updater, update the offset. Don't set any parameter, so the X will be the offset + delta.x
            this.updateOffset();
        }
    };

    /**
     *
     * @param {number} [x] - X-offset to scroll to. When ommitted, the x will be (this.offset + this.delta.x)
     * @param {object} [transitionSettings] - Settings for animating the transition to the new x
     * @param {boolean} [transitionSettings.transition=false] - True if you want to add a transition
     * @param {string} [transitionSettings.timingFunction=ease-out] - The CSS Transition timing function to use.
     * @param {boolean} [transitionSettings.fastAnimation=false] - Use a fast animation (0.2s) instead of a slow animation (0.3s)
     */
    ContentSlider.prototype.updateOffset = function (x, transitionSettings) {
        x = (typeof x === 'number' ? x : (this.offset + this.delta.x));
        transitionSettings = (typeof transitionSettings === 'object' ? transitionSettings : {});

        if (transitionSettings.transition === undefined) {
            transitionSettings.transition = false;
        }

        if (transitionSettings.timingFunction === undefined) {
            transitionSettings.timingFunction = 'ease-out';
        }

        if (transitionSettings.fastAnimation === undefined) {
            transitionSettings.fastAnimation = false;
        }

        // When we're trying to reach a positive X (so we're sliding from right to left), add an increasing delay to create a bounce effect
        if (x > 0) {
            x = x / (1 + (x / (this.sliderWrapWidth / this.itemCount)));
        }

        // Same as x>0, create a bounce effect when we past the last item
        if (Math.abs(x) > this.lastItemStart) {
            if (this.itemCount > this.visibleCount) {
                x = ((x + this.lastItemStart) / ((Math.abs(x) - this.lastItemStart) / (this.lastItemStart / this.itemCount) + 1)) - this.lastItemStart;
            } else {
                x = x / ((x / this.lastItemStart / this.itemCount) + 1);
            }
        }

        if (document.documentElement.className.indexOf('no-csstransforms3d') > -1) {
            this.itemWrap.style.msTransform = 'translateX(' + x + 'px)';
            this.itemWrap.style.webkitTransform = 'translateX(' + x + 'px)';
            this.itemWrap.style.transform = 'translateX(' + x + 'px)';
        } else {
            this.itemWrap.style.webkitTransform = 'translate3d(' + x + 'px, 0, 0)';
            this.itemWrap.style.transform = 'translate3d(' + x + 'px, 0, 0)';
        }

        if (transitionSettings.transition) {
            this.itemWrap.style.webkitTransition = 'transform ' + (transitionSettings.fastAnimation ? '0.2' : '0.3') + 's ' + transitionSettings.timingFunction;
            this.itemWrap.style.transition = 'transform ' + (transitionSettings.fastAnimation ? '0.2' : '0.3') + 's ' + transitionSettings.timingFunction;

            this.itemWrap.addEventListener(window.MaxServ.transitionEndEventName, this.transitionFinished);
        }
    };

    /**
     * Slide the number of visible items back
     */
    ContentSlider.prototype.slideBack = function () {
        if (this.offset !== 0) {
            this.offset = this.limitOffset(this.offset + (this.itemWidth * this.visibleCount));
        } else {
            // We're going back while we're already at the beginning, add a little bounce effect
            this.offset = 100;
            window.setTimeout(this.slideBack, 150);
        }

        this.setActiveBullet(Math.ceil(this.offset / this.itemWidth / this.visibleCount * -1));


        requestAnimationFrame(this.updateOffset.bind(this, this.offset, {
            transition: true,
            timingFunction: 'ease-in-out'
        }));
    };

    /**
     * Slide the number of visible items forward
     */
    ContentSlider.prototype.slideForward = function () {
        if (this.itemCount < this.visibleCount) {
            if (this.offset === -100) {
                this.offset = 0;
            } else {
                this.offset = -100;
                window.setTimeout(this.slideForward, 150);
            }
        } else if (Math.abs(this.offset) !== this.lastItemStart) {
            this.offset = this.limitOffset(this.offset - (this.itemWidth * this.visibleCount));
        } else {
            // We're going forward while we're already at the end, add a little bounce effect
            this.offset = this.lastItemStart * -1 - 100;
            window.setTimeout(this.slideForward, 150);
        }

        this.setActiveBullet(Math.ceil(this.offset / this.itemWidth / this.visibleCount * -1));


        requestAnimationFrame(this.updateOffset.bind(this, this.offset, {
            transition: true,
            timingFunction: 'ease-in-out'
        }));
    };

    ContentSlider.prototype.bulletClick = function (event) {
        var itemIndex = Array.prototype.indexOf.call(this.bulletWrap.children, event.target);
        this.setActiveBullet(itemIndex);

        this.offset = this.limitOffset(itemIndex * this.visibleCount * this.itemWidth * -1);
        requestAnimationFrame(this.updateOffset.bind(this, this.offset, {
            transition: true,
            timingFunction: 'ease-in-out'
        }));
    };

    ContentSlider.prototype.setActiveBullet = function (itemIndex) {
        if (this.bulletWrap === null) {
            return;
        }

        var activeBullets = this.bulletWrap.querySelectorAll('.ContentSlider-bullet--active'),
            i;

        for (i = 0; i < activeBullets.length; i += 1) {
            activeBullets[i].className = 'ContentSlider-bullet';
        }

        if (itemIndex < this.bulletWrap.children.length) {
            this.bulletWrap.children[itemIndex].className = 'ContentSlider-bullet ContentSlider-bullet--active';
        }
    };

    /**
     * Make sure the offset can't be higher than 0 and can't be lower than the {@link this.lastItemStart}.
     * @param {number} offset
     * @returns {number} - The corrected offset
     */
    ContentSlider.prototype.limitOffset = function (offset) {
        if (offset > 0) {
            offset = 0;
        } else if (offset < this.lastItemStart * -1) {
            offset = this.lastItemStart * -1;
        }

        return offset;
    };

    /**
     * When a transition has finished, set the {@link this.itemWrap}'s transition to none + remove the eventlistener
     */
    ContentSlider.prototype.transitionFinished = function () {
        this.itemWrap.style.transition = 'none';
        this.itemWrap.removeEventListener(window.MaxServ.transitionEndEventName, this.transitionFinished);
    };

    window.ContentSlider = ContentSlider;
}());
