/*global document, jQuery, window */
jQuery(document).ready(function ($) {
    'use strict';

    var $slideNav = $('.TimelineSlider-sliderNav'),
        $slideMain = $('.TimelineSlider-sliderFor');

    function initTimelineSlider() {

        $slideMain.each(function () {
            var relatedNavSlider = $(this) .parents('.TimelineSlider').find('.TimelineSlider-sliderNav');

            $(this).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: relatedNavSlider,
                autoplay: true,
                speed: 500,
                dots: false,
                arrows: false
            });
        });

        $slideNav.each(function () {
            var relatedMainSlider = $(this) .parents('.TimelineSlider').find('.TimelineSlider-sliderFor'),
                currentElement = $(this);

            $(this).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: relatedMainSlider,
                autoplay: true,
                speed: 500,
                arrows: false,
                dots: true,
                customPaging: function (slider, i) {
                    var year = $(slider.$slides[i]).data('year');
                    return "<div class='TimelineSlider-dots'>" + year + "</div>";
                },
                onAfterChange: function(event, slick, currentSlide) {
                    var totalSlides = currentElement.find('.slick-dots').find('li').length,
                        slideWidth = currentElement.find('.slick-dots').find('li').width(),
                        parentWidth = currentElement.find('.slick-dots').parent().width(),
                        divWidth = slideWidth * totalSlides,
                        currentPosition = currentElement.slickCurrentSlide(), //count starts from zero
                        positionCalc = (currentPosition * slideWidth) * -1 + slideWidth + 'px',
                        positionCalcPos0 = (currentPosition * slideWidth) * -1 + 'px';

                    if (currentPosition > 0 && (parentWidth + 60) < divWidth) {
                        currentElement.find('.slick-dots').css({
                            'transform':'translateX(' + positionCalc + ')',
                            'transition':'transform 0.3s'
                        });
                    }
                    if (currentPosition === 0 && (parentWidth + 60) < divWidth) {
                        currentElement.find('.slick-dots').css({
                            'transform':'translateX(' + positionCalcPos0 + ')',
                            'transition':'transform 0.3s'
                        });
                    }

                }
            });
        });
    }

    $(window).on('resize', Foundation.util.throttle(function () {
        $('.TimelineSlider-sliderFor').add('.TimelineSlider-sliderNav.slick-slider').unslick();
        initTimelineSlider();
    }, 300));

    initTimelineSlider();
});
