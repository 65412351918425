(function () {
    const productColors = document.querySelectorAll('.js-ProductPanel--color');
    const productImages = document.querySelectorAll('.js-ColoredProduct--image');

    productColors.forEach(element => {

        const toggleImage = (event) => {
            event.preventDefault();
            const { productId }  = event.target.dataset;

            productImages.forEach(imageElement => {
                const { productId: productImageId } = imageElement.dataset;

                if (productImageId === productId) {
                    imageElement.classList.remove('hide');
                } else {
                    imageElement.classList.add('hide');
                }
            });

            // Set the checkmark
            element.classList.add('is-active');

            // Remove the checkmark for the other elements
            Array.from(productColors)
                .filter(color => color !== element)
                .forEach(element => element.classList.remove('is-active'));
        };

        element.addEventListener('click', toggleImage, false);
    });
})();
