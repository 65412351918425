/*global document, jQuery */
jQuery(document).ready(function ($) {
    'use strict';

    var
        checkbox = $('input[type="checkbox"]'),
        checkboxLabels = checkbox.parent().find('label'),
        radiobtn = $('input[type="radio"]'),
        // Target all Radio labels, but not the ones from the BudgetPlanner
        radioLabels = radiobtn.parent().find('label').not('.bptMultipleItemGrid-item-label, .bptItemList-item-label'),
        radioWrapper = $('.powermail_radio_outer'),
        select = $('select').not('.js-dropDownFilter, .pika-select');

    if (!radioLabels.next().hasClass('CustomRadio')) {
        $('<a href="#" class="CustomRadio js-customInput" tabindex="-1"></a> ').insertAfter(radioLabels);
    }
    if (!checkboxLabels.next().hasClass('CustomCheckbox') && !checkboxLabels.hasClass('mx_read-more__label')) {
        $('<a href="#" class="CustomCheckbox js-customInput" tabindex="1"></a> ').insertAfter(checkboxLabels);
    }

    // Radio buttons functionality
    radioWrapper.each(function () {
        // Make radio buttons in powermail forms prefilled
        $(this).find('input[type="radio"]').first().attr('checked', 'checked');
    });

    $('.js-customInput').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).parent().find('input[type="radio"], input[type="checkbox"]').trigger('click');
    });

    select.each(function () {
        $(this).wrap('<div class="CustomSelect"></div>');
    });
});
